<template>
	<MhView :isLoading="isLoading">

		<template v-if="$root.isSmallDevice">		
			<MobilePageTitle></MobilePageTitle>		
		</template>	

		<!-- desktop -->
		<div class="projectDetailPost border border--bottom" :style="wrapperElmStyles" v-if="post && !$root.isSmallDevice">
			
			<!-- slider -->
			<div class="projectDetailPost__leftCol border border--right border--bottom col col--7of12">
				<MhSlider class="projectDetailPost__imageSlider">
					<div class="projectDetailPost__slide" v-for="(slide, index) in post.acf.slides2" :key="index">
						<MhImage 
							class="projectDetailPost__slideImage"
							:imageObject="slide.image" 
							backgroundPosition="center center"
							mode="contain">
						</MhImage>	
						<div class="projectDetailPost__slideBu hSpace hSpace--big vSpace vSpace--default">
							<span class="font font--sizeMini" v-html="$root.getTranslatedAcfValue( slide, 'bu')"></span>
						</div>					
					</div>
				</MhSlider>
			</div>
			
			<!-- title + text -->
			<div class="projectDetailPost__rightCol col col--5of12 col--sm-12of12">
				<div 	class="projectDetailPost__title hSpace hSpace--big vSpace vSpace--projectTitlePost border border--bottom">
					<span class="font font--sizeBig">
						{{$root.getTranslatedValue('Projekt')}} · 
						{{$root.getTranslatedAcfValue( post.acf, 'postTitle')}}
					</span><br />
					<span class="font font--sizeSmall">
						{{post.acf.makerName}} 
						<template v-if="getFilterPostById(post.acf.semester)">· {{$root.getTranslatedAcfValue( getFilterPostById(post.acf.semester).acf, 'postTitle')}}</template> 
						<template v-if="post.acf.thirdMeta__de"> · {{post.acf.thirdMeta__de}}</template>
					</span>
				</div>
				
				<div class="projectDetailPost__text border border--bottom">
					<div class="projectDetailPost__textScroller hideScrollbars">
						<div class="hSpace hSpace--big vSpace vSpace--default" v-html="$root.getTranslatedAcfValue( post.acf, 'text')">
						</div>
						<div class="hSpace hSpace--big vSpace vSpace--default">
							<a  v-if="post.acf.pdfLink" :href="post.acf.pdfLink.url" target="_blank">PDF</a>
							<br v-if="post.acf.pdfLink"/>
							<a  v-if="post.acf.relatedProgrammPost" :href="post.acf.relatedProgrammPost">{{$root.getTranslatedValue('Aufgabenstellung')}}</a>
						</div>
						<br />
					</div>
					<div class="fadeOut"></div>
				</div>

			</div>
			
		</div>
		
		<!-- mobile -->
		<template v-if="post && $root.isSmallDevice">
			
			<!-- title -->
			<h2 class="border border--bottom" >
				<div 	class="hSpace hSpace--big vSpace vSpace--projectTitlePost">
					<span class="font font--sizeBig">
						{{$root.getTranslatedValue('Projekt')}} · {{$root.getTranslatedAcfValue( post.acf, 'postTitle')}}
					</span><br />
					<span class="font font--sizeSmall">
						{{post.acf.makerName}} · <template v-if="getFilterPostById(post.acf.semester)">{{$root.getTranslatedAcfValue( getFilterPostById(post.acf.semester).acf, 'postTitle')}} ·</template> {{post.acf.thirdMeta__de}}					
					</span>
				</div>
			</h2>

			<!-- slider -->
			<MhSlider class="projectDetailPost__imageSlider">
				<div class="projectDetailPost__slide" v-for="(slide, index) in post.acf.slides2" :key="index">
					<MhImage 
						class="projectDetailPost__slideImage"
						:imageObject="slide.image" 
						backgroundPosition="center center"
						mode="contain">
					</MhImage>	
					<div class="projectDetailPost__slideBu hSpace hSpace--big vSpace vSpace--default">
						<span class="font font--sizeMini" v-html="$root.getTranslatedAcfValue( slide, 'bu')"></span>
					</div>					
				</div>
			</MhSlider>

			<!-- text -->
			<div class="projectDetailPost__text border border--bottom">
				<div class="hSpace hSpace--big vSpace vSpace--default" v-html="$root.getTranslatedAcfValue( post.acf, 'text')">
				</div>
				<div class="hSpace hSpace--big vSpace vSpace--default">
					<a  v-if="post.acf.pdfLink" :href="post.acf.pdfLink.url" target="_blank">PDF</a>
					<br v-if="post.acf.pdfLink"/>
					<a  v-if="post.acf.relatedProgrammPost" :href="post.acf.relatedProgrammPost">{{$root.getTranslatedValue('Aufgabenstellung')}}</a>
				</div>
				<br />
			</div>		

		</template>
		
		<!-- related -->
		<template v-if="post">
			<RelatedPosts	class="projectDetailPost__relatedPosts" 
							title="Verwandte Projekte"
							:postIds="post.acf.relatedPosts__projectPosts" 
							endPoint="projectPosts"></RelatedPosts>
		</template>
		
	</MhView>
</template>

<script>
	
	import MhView from '@/components/MhView/MhView.vue'
	import MhImage from '@/components/MhImage/MhImage.vue'
	import MhSlider from '@/components/MhSlider/MhSlider.vue'
	import RestHandler from '@/components/RestHandler/RestHandler.js'
	import RelatedPosts from '@/components/RelatedPosts.vue'
	import { EventBus } from '@/event-bus.js'
	import MobilePageTitle from '@/components/MobilePageTitle.vue'

	export default {
		name: 'ProjectDetailView',
		components: {
			MhView,
			MhImage,
			MhSlider,
			RelatedPosts,
			MobilePageTitle,
		},
		mixins: [ RestHandler ],
		data() {
			return {	
				post: null,
				filterPosts: [],
				isLoading : true,
				activeSlide : null,	// TODO: kann raus
				//appHeaderHeight : 0 // wird via EventBus.$on('appHeaderResized') gesetzt		
			}
		},
		computed: {
			wrapperElmStyles() {
				const appHeaderHeight = this.$root.appHeaderHeight
				const elmHeight = 'calc(100vh - ' + appHeaderHeight + 'px + 1px)'
				
				if( !this.$root.isSmallDevice ){
					return {
						transition : 'all 0.1s linear',
						height     : elmHeight,
						maxHeight  : elmHeight,
					}
				}else{
					return {}
				}

			},
		},
		methods: {
			getFilterPostById( id ) {
				const filterPosts = this.filterPosts				
				return this._.findLast(filterPosts, {id})
			},	
			XXXonAfterSlideChanged( pageNumber ) {	// TODO: kann raus
				this.activeSlide = pageNumber
			},
		},
		mounted() {
			
			// fetch post and all related filterPosts
			this.restHandler__fetch({
				action : 'GET',				
				route : '/wp-json/wp/v2/projectPosts',
				params : {
					slug : this.$route.params.postSlug,
				},
				callback : (response) => {
					
					// asign fetched post
					this.post = response.data[0]

					// fetch all related filterPosts
					this.restHandler__fetch({
						action : 'GET',				
						route : '/wp-json/mh/v1/usedFilters',
						params : {
							postId : this.post.id,
						},
						callback : (response) => {
							// asign fetched posts
							this.filterPosts = response.data.result
							
							// remove loading state
							this.isLoading = false
						}
					})
					
				}
			})
			
			// post container ist zusammen mit der header height 100vh
			// wenn sich die header höhe ändert, wird die neue height 
			// hier via event empfangen und dient der berechnung der 
			// höhe des post containers
			/*
			EventBus.$on('appHeaderResized', elmHeight => {
				this.appHeaderHeight = elmHeight				
				//console.log( elmHeight )
			})
			*/
			
		},		
	}
</script>

<style lang="less">	
	@import "../less/includes/grid.include.less";
	
	.projectDetailPost {
		//background-color: fade(red, 20);
		flex-grow: 1;
		transition: height 0.1s linear, max-height 0.1s linear;
		height: inherit;  // wird jetzt über js gesetzt um auf die appHeader height reagieren zu können
		display: flex;
		
		&__leftCol {
			display: flex;
			flex-grow: 1;
			height: inherit;
			overflow: hidden;
		}
		&__rightCol {
			display: flex;
			flex-grow: 1;
			height: inherit;
			flex-direction: column;
		}
		&__title {
			//background-color: fade(yellow, 20);
			flex-grow: 0;	
			flex-shrink: 0;	
		}
		&__textWrapper {
			//background-color: fade(green, 20);	
			flex-shrink: 0;	
			flex-grow: 1;	
			display: flex;
		}
		&__text {
			//background-color: fade(green, 20);	
			position: relative;
			flex-grow: 1;
			overflow: hidden;		
			
			display: flex;	// 2019-03-15  safari braucht das damit der inner die höhe übernimmt
			flex-direction: column;
				
		}
		&__textScroller {
			height: 100%;
			overflow: hidden;				
			overflow-y: auto;
			
			height: inherit; // 2019-03-15  safari braucht das damit der inner die höhe übernimmt
		}
		
		&__imageSlider {
			[showborders1] & { background-color: fade(red, 20); }
			
			flex-grow: 1;
			overflow: hidden;		
		}
		&__slide {
			[showborders1] & { background-color: fade(red, 20); }
			
			position: relative;
			display: flex;
		}
		&__slideImage {
			flex-grow: 1;
		}
		&__slideBu {
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 10;
			flex-grow: 0;
			flex-shrink: 0;
			padding-bottom: 1em;
			
			span {
				display: block;
			}
		}
		.slider-pagination {
			display: none;
		}
	
	}

	@media @mediaQuery__sm {
		
		@slider__bodyHeight: 100vw;
		@slider__footerHeight: 3em;
		
		.projectDetailPost__imageSlider {
			height: calc(@slider__bodyHeight + @slider__footerHeight);
			flex-grow: 0;
		}
		.projectDetailPost__slideBu {
			display: none;
		}
		.slider-wrap {
			height: @slider__bodyHeight !important;			
		}
		
		.mhSlider__sliderBtn {
			top: auto;
			height: @slider__footerHeight;
			width: @slider__footerHeight;
			padding: 0em;
			
			background-size: 25%;
			background-position: right 1em center;
		}
		.slider-pagination {
			//background-color: fade( red, 20 );
			
			position: absolute;
			left: 0; right: 0;
			bottom: 0 !important;
			height: @slider__footerHeight;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0em;			
		}
		.slider-pagination-bullet.active {
			background-color: black !important;
		}
		.mhSlider--atEnd.mhSlider--atStart { // remove swipe if there is only one slide
			pointer-events: none;
		}
		.mhSlider--atEnd.mhSlider--atStart .slider-pagination { // hide dot if there is only one slide
			display: none;
		}
	
		.projectDetailPost__imageSlider .mhImage__image {
			padding-bottom: 0 !important;
			height: @slider__bodyHeight !important;
			background-size: contain !important;  
		}
	
	}
</style>
